import React from 'react'
import Layout from 'gatsby-theme-kn/src/components/modules/Layout'
import BodyBlocks from 'gatsby-theme-kn/src/components/technical/BodyBlocks'

const DemoPage = () => {
  const body = [
    {
      component: 'academy_course_block',
      headline: 'Eine wunderbare Heiterkeit',
      courses: [
        {
          image: {
            filename: 'https://picsum.photos/1920/1080',
          },
          alt: 'Platzhalter-Bild',
          title: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Ein Link',
        },
        {
          image: {
            filename: 'https://picsum.photos/1920/1080',
          },
          alt: 'Platzhalter-Bild',
          title: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Ein Link',
        },
        {
          image: {
            filename: 'https://picsum.photos/1920/1080',
          },
          alt: 'Platzhalter-Bild',
          title: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Ein Link',
        },
        {
          image: {
            filename: 'https://picsum.photos/1920/1080',
          },
          alt: 'Platzhalter-Bild',
          title: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Ein Link',
        },
      ],
    },
    {
      component: 'accordion_block',
      accordion_card: [
        {
          item_title: 'Titel vom ersten Tab',
          content: [
            {
              component: 'image_block',
              image: { filename: 'https://picsum.photos/1920/1080' },
            },
          ],
        },
        {
          item_title: 'Titel vom zweiten Tab',
          content: [
            {
              component: 'text_block',
              headline: 'Aktuell Sommer 2020',
              text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken. ',
            },
          ],
        },
      ],
    },
    {
      component: 'background_block',
      type: 'bg-image-ready bg-overlay',
      background_image: 'https://picsum.photos/1920/1080',
      body: [
        {
          component: 'text_block',
          kicker: 'Literatur',
          headline: 'Eine wunderbare Heiterkeit',
          subheadline: 'Johann Wolfgang von Goethe',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
        },
      ],
    },
    {
      component: 'big_numbers_block',
      headline: 'Fakten zum Werk',
      subheadline: 'von Johann Wolfgang von Goethe',
      big_numbers: [
        {
          prefix: '',
          number: '165',
          title: 'Seiten',
          subtitle: 'Die Leiden des jungen Werther',
        },
        {
          prefix: '',
          number: '165',
          title: 'Seiten',
          subtitle: 'Die Leiden des jungen Werther',
        },
        {
          prefix: '',
          number: '165',
          title: 'Seiten',
          subtitle: 'Die Leiden des jungen Werther',
        },
      ],
    },
    {
      component: 'big_text_block',
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
    },
    {
      component: 'buttons_block',
      buttons: [
        {
          link: '/',
          link_text: 'button',
        },
        {
          link: '/',
          link_text: 'button',
        },
      ],
    },
    {
      component: 'centered_text_block',
      kicker: 'Literatur',
      headline: 'Eine wunderbare Heiterkeit',
      subheadline: 'Johann Wolfgang von Goethe',
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
    },
    {
      component: 'changelog_block',
      month: '12',
      year: '2020',
      kicker: 'Literatur',
      headline: 'Eine wunderbare Heiterkeit',
      subheadline: 'Johann Wolfgang von Goethe',
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
      link: '/',
      link_text: 'Button',
      icon: {
        type: 'fa',
        icon: 'fa-plus',
      },
    },
    {
      component: 'contact_person_block',
      image: { filename: 'https://picsum.photos/600/600' },
      name: 'Max Mustermann',
      department: 'Musterabteilung',
      tel: '123456789',
      fax: '987654321',
      email: 'mmustermann@muster.de',
    },
    {
      component: 'custom_article_triple_teaser_block',
      headline: 'Eine wunderbare Heiterkeit',
      teaser: [
        {
          link: '/',
          category: 'Literatur',
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link_text: 'Mehr',
        },
        {
          link: '/',
          category: 'Literatur',
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link_text: 'Mehr',
        },
        {
          link: '/',
          category: 'Literatur',
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link_text: 'Mehr',
        },
      ],
    },
    {
      component: 'double_teaser_block',
      headline: 'Eine wunderbare Heiterkeit',
      teaser: [
        {
          link: '/',
          image: { filename: 'https://picsum.photos/1080/1080' },
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen.',
          link_text: 'Mehr',
          meta_info: 'Meta-Info',
        },
        {
          link: '/',
          image: { filename: 'https://picsum.photos/1080/1080' },
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen.',
          link_text: 'Mehr',
          meta_info: 'Meta-Info',
        },
      ],
    },
    {
      component: 'downloads_block',
      catalogue_kicker: 'Stuhl',
      catalogue_title: 'Produkt-Katalog',
      catalogue_subtitle: 'Ausführliche Informationen und Produktübersicht',
      catalog: [
        {
          original_url: '/',
          preview_url: { filename: 'https://picsum.photos/600/800' },
        },
      ],
      downloads_title: 'Produkt-Downloads',
      product_downloads: [
        {
          title: 'Ein Produkt',
          original_url: '/produkt.jpg',
          extension: '.jpg',
        },
        {
          title: 'Ein Produkt',
          original_url: '/produkt.pdf',
          extension: '.pdf',
        },
      ],
      certificates_title: 'Zertifikate',
      certificates: [
        {
          title: 'Zertifikat',
          original_url: '/zertifikat.pdf',
          extension: '.pdf',
        },
        {
          title: 'Zertifikat',
          original_url: '/zertifikat.zip',
          extension: '.zip',
        },
      ],
    },
    {
      component: 'embed_block',
      aspect_ratio: '16by9',
      code: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d163720.8725399987!2d8.496481234885447!3d50.121127742312346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd096f477096c5%3A0x422435029b0c600!2sFrankfurt%20am%20Main!5e0!3m2!1sde!2sde!4v1600262389997!5m2!1sde!2sde" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
    },
    {
      component: 'events_from_partnerportal_block',
      code: '<h2>Event-Code aus dem Partnerportal?</h2>',
    },
    {
      component: 'feature_teaser_block',
      mirrored: true,
      image: { filename: 'https://picsum.photos/1920/1080' },
      headline: 'Feature Teaser',
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
      link: '/',
      link_text: 'Mehr',
    },
    {
      component: 'fullscreen_image_block',
      image: { filename: 'https://picsum.photos/1920/1080' },
      caption: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen.',
    },
    {
      component: 'fullscreen_video_block',
      video: 'https://www.youtube.com/watch?v=PcIlZ3luYHc',
      caption: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen.',
    },
    {
      component: 'gallery_block',
      images: [
        {
          image: { filename: 'https://picsum.photos/1920/1080' },
          title: 'Eine wunderbare Heiterkeit',
        },
        {
          image: { filename: 'https://picsum.photos/1920/1080' },
          title: 'Eine wunderbare Heiterkeit',
        },
        {
          image: { filename: 'https://picsum.photos/1920/1080' },
          title: 'Eine wunderbare Heiterkeit',
        },
        {
          image: { filename: 'https://picsum.photos/1920/1080' },
          title: 'Eine wunderbare Heiterkeit',
        },
      ],
    },
    {
      component: 'header_block',
      kicker: 'Literatur',
      headline: 'Eine wunderbare Heiterkeit',
      subheadline: 'Johann Wolfgang von Goethe',
    },
    {
      component: 'hero_block',
      image: { filename: 'https://picsum.photos/1920/1080' },
      headline: 'Eine wunderbare Heiterkeit',
    },
    {
      component: 'hero_block',
      hero_variant_class: 'kn-product-hero',
      image: { filename: 'https://picsum.photos/1920/1080' },
      headline: 'Eine wunderbare Heiterkeit',
      meta_nav_items: [
        {
          title: 'Video',
        },
        {
          title: 'Bild',
        },
        {
          title: 'Text',
        },
      ],
    },
    {
      component: 'hero_rotation_block',
      hero_rotation_block_content: [
        {
          image: { filename: 'https://picsum.photos/1920/1080' },
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          hero_mirrored: true,
          image: { filename: 'https://picsum.photos/1920/1080' },
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Mehr erfahren',
        },
      ],
    },
    {
      component: 'highlights_block',
      highlights: [
        {
          image: { filename: 'https://picsum.photos/1920/1080' },
          title: 'Eine wunderbare Heiterkeit',
          subtitle:
            'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          image: { filename: 'https://picsum.photos/1920/1080' },
          title: 'Eine wunderbare Heiterkeit',
          subtitle:
            'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          image: { filename: 'https://picsum.photos/1920/1080' },
          title: 'Eine wunderbare Heiterkeit',
          subtitle:
            'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          image: { filename: 'https://picsum.photos/1920/1080' },
          title: 'Eine wunderbare Heiterkeit',
          subtitle:
            'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          image: { filename: 'https://picsum.photos/1920/1080' },
          title: 'Eine wunderbare Heiterkeit',
          subtitle:
            'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Mehr erfahren',
        },
      ],
    },
    {
      component: 'image_block',
      image: {
        filename: 'https://picsum.photos/1920/1080',
      },
    },
    {
      component: 'image_slider_block',
      mirrored: true,
      slides: [
        {
          image: {
            filename: 'https://picsum.photos/1280/960',
          },
          title: 'Eine wunderbare Heiterkeit',
          subtitle:
            'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          image: {
            filename: 'https://picsum.photos/1280/960',
          },
          title: 'Eine wunderbare Heiterkeit',
          subtitle:
            'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          link: '/',
          link_text: 'Mehr erfahren',
        },
      ],
    },
    {
      component: 'inspiration_header_block',
      headline: 'Eine wunderbare Heiterkeit',
    },
    {
      component: 'inspiration_block',
      headline: 'Eine wunderbare Heiterkeit',
      image: {
        filename: 'https://picsum.photos/1397/786',
      },
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
      link: '/',
      product_list_headline: 'Produkte',
      product_list: [
        {
          link: '/',
          link_text: 'Produkt A',
        },
        {
          link: '/',
          link_text: 'Produkt B',
        },
      ],
    },
    {
      component: 'logo_links_block',
      logos: [
        {
          link: '/',
          logo_url: {
            filename: 'https://picsum.photos/800/400',
          },
        },
        {
          link: '/',
          logo_url: {
            filename: 'https://picsum.photos/800/400',
          },
        },
        {
          link: '/',
          logo_url: {
            filename: 'https://picsum.photos/800/400',
          },
        },
        {
          link: '/',
          logo_url: {
            filename: 'https://picsum.photos/800/400',
          },
        },
        {
          link: '/',
          logo_url: {
            filename: 'https://picsum.photos/800/400',
          },
        },
        {
          link: '/',
          logo_url: {
            filename: 'https://picsum.photos/800/400',
          },
        },
      ],
    },
    {
      component: 'product_category_teaser_block',
      headline: 'Eine wunderbare Heiterkeit',
      subheadline: 'Johann Wolfgang von Goethe',
      product_category_teaser_content: [
        {
          link: '/',
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          image: {
            filename: 'https://picsum.photos/700/600',
          },
        },
        {
          link: '/',
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
          image: {
            filename: 'https://picsum.photos/700/600',
          },
        },
      ],
    },
    {
      component: 'product_gallery_block',
      body: [
        {
          augmented_image: {
            image: {
              filename: 'https://picsum.photos/1397/786',
            },
            hotspots: [
              {
                x: 75,
                y: 33,
                internallinkurl: '/',
                title: 'Eine wunderbare Heiterkeit',
                subtitle: 'Johann Wolfgang von Goethe',
              },
              {
                x: 55,
                y: 66,
                internallinkurl: '/',
                title: 'Eine wunderbare Heiterkeit',
                subtitle: 'Johann Wolfgang von Goethe',
              },
            ],
          },
        },
        {
          augmented_image: {
            image: {
              filename: 'https://picsum.photos/1397/786',
            },
            hotspots: [
              {
                x: 75,
                y: 50,
                internallinkurl: '/',
                title: 'Eine wunderbare Heiterkeit',
                subtitle: 'Johann Wolfgang von Goethe',
              },
            ],
          },
        },
      ],
    },
    {
      component: 'quadruple_teaser_block',
      Kicker: 'Literatur',
      headline: 'Eine wunderbare Heiterkeit',
      subheadline: 'Johann Wolfgang von Goethe',
      teaser: [
        {
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
          image: {
            filename: 'https://picsum.photos/1920/1080',
          },
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
          image: {
            filename: 'https://picsum.photos/1920/1080',
          },
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
          image: {
            filename: 'https://picsum.photos/1920/1080',
          },
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
          image: {
            filename: 'https://picsum.photos/1920/1080',
          },
          link: '/',
          link_text: 'Mehr erfahren',
        },
      ],
    },
    {
      component: 'quote_block',
      quote: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen',
      author: 'Der junge Werther',
      position: 'Buch-Charakter',
    },
    {
      component: 'reference_slider_block',
      reference_slider_block_content: [
        {
          image: {
            filename: 'https://picsum.photos/1280/960',
          },
          customer_logo: {
            filename: 'https://picsum.photos/960/540',
          },
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          image: {
            filename: 'https://picsum.photos/1280/960',
          },
          customer_logo: {
            filename: 'https://picsum.photos/960/540',
          },
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
          link: '/',
          link_text: 'Mehr erfahren',
        },
      ],
    },
    {
      component: 'static_downloads_block',
      headline: 'Eine wunderbare Heiterkeit',
      description:
        'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
      file_downloads: [
        {
          title: 'Platzhalter',
          file: 'https://picsum.photos/1080/1080',
          extension: '.jpg',
        },
        {
          title: 'Platzhalter',
          file: 'https://picsum.photos/1080/1080',
          extension: '.jpg',
        },
      ],
    },
    {
      component: 'table_block',
      headline: 'Tabelle',
      table: {
        tbody: [
          ['1-1', '1-2'],
          ['2-1', '2-2'],
        ],
        thead: ['Head 1', 'Head 2'],
      },
    },
    {
      component: 'team_block',
      headline: 'Team',
      team_members: [
        {
          image: { filename: 'https://picsum.photos/600/600' },
          name: 'Max Mustermann',
          department: 'Musterabteilung',
          tel: '123456789',
          fax: '987654321',
          email: 'mmustermann@muster.de',
        },
        {
          image: { filename: 'https://picsum.photos/600/600' },
          name: 'Max Mustermann',
          department: 'Musterabteilung',
          tel: '123456789',
          fax: '987654321',
          email: 'mmustermann@muster.de',
        },
        {
          image: { filename: 'https://picsum.photos/600/600' },
          name: 'Max Mustermann',
          department: 'Musterabteilung',
          tel: '123456789',
          fax: '987654321',
          email: 'mmustermann@muster.de',
        },
      ],
    },
    {
      component: 'testimonial_block',
      image: { filename: 'https://picsum.photos/600/600' },
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
      author: 'Der junge Werther',
      author_description: 'Roman-Figur',
    },
    {
      component: 'text_block',
      kicker: 'Literatur',
      headline: 'Eine wunderbare Heiterkeit',
      subheadline: 'Johann Wolfgang von Goethe',
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
    },
    {
      component: 'text_with_image_block',
      kicker: 'Literatur',
      headline: 'Eine wunderbare Heiterkeit',
      subheadline: 'Johann Wolfgang von Goethe',
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
      image: {
        filename: 'https://picsum.photos/1920/1080',
      },
    },
    {
      component: 'text_with_list_block',
      kicker: 'Literatur',
      headline: 'Eine wunderbare Heiterkeit',
      subheadline: 'Johann Wolfgang von Goethe',
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
      list_headline: 'Eine wunderbare Heiterkeit',
      list: [
        {
          title: 'Faust I',
          subtitle: 'von Johann Wolfgang von Goethe',
        },
        {
          title: 'Die Leiden des jungen Werther',
          subtitle: 'von Johann Wolfgang von Goethe',
        },
      ],
    },
    {
      component: 'text_with_portrait_image_block',
      kicker: 'Literatur',
      headline: 'Eine wunderbare Heiterkeit',
      subheadline: 'Johann Wolfgang von Goethe',
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
      image: {
        filename: 'https://picsum.photos/1920/1080',
      },
    },
    {
      component: 'text_with_quote_block',
      kicker: 'Literatur',
      headline: 'Eine wunderbare Heiterkeit',
      subheadline: 'Johann Wolfgang von Goethe',
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
      quote: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen',
      author: 'Der junge Werther',
      position: 'Buch-Charakter',
    },
    {
      component: 'triple_teaser_block',
      Kicker: 'Literatur',
      headline: 'Eine wunderbare Heiterkeit',
      subheadline: 'Johann Wolfgang von Goethe',
      teaser: [
        {
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
          image: {
            filename: 'https://picsum.photos/1920/1080',
          },
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
          image: {
            filename: 'https://picsum.photos/1920/1080',
          },
          link: '/',
          link_text: 'Mehr erfahren',
        },
        {
          headline: 'Eine wunderbare Heiterkeit',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
          image: {
            filename: 'https://picsum.photos/1920/1080',
          },
          link: '/',
          link_text: 'Mehr erfahren',
        },
      ],
    },
    {
      component: 'vertical_timeline_block',
      vertical_timeline_block_content: [
        {
          date: '1749',
          text: '**Johann Wolfgang von Goethe** wird in _Frankfurt am Main_ geboren',
        },
        {
          date: '1832',
          text: '**Johann Wolfgang von Goethe** stirbt in _Weimar_',
        },
      ],
    },
    {
      component: 'video_block',
      image: {
        filename: 'https://picsum.photos/1920/1080',
      },
      alt: 'Platzhalter-Bild',
      headline: 'Eine wunderbare Heiterkeit',
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine.',
    },
  ]
  return (
    <Layout pageName="demo">
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default DemoPage
